import * as THREE from 'three';

var scene, camera, light, directionalLight, renderer;

const sceneColor = 0x6e6e6e;

const far = 1000;
const near = 0.1;
const fov = 60;

export class Initializer {

    initialize(){
        this.sceneInit();
        this.cameraInit();
        this.lightInit();
        this.rendererInit();
    }

    sceneInit(){
        scene = new THREE.Scene();
        window.scene = scene;
        scene.background = new THREE.Color( sceneColor );
    }

    cameraInit(){
        var aspect = window.innerWidth / window.innerHeight;
        camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        camera.position.set(0, 0, 1);
        window.camera = camera;
    }

    lightInit(){
        light = new THREE.AmbientLight( 0x7e3ca3  ); // soft light
        scene.add( light );

        directionalLight = new THREE.DirectionalLight( 0x7e3ca3, 1 );
        scene.add( directionalLight );
        directionalLight.position.set(0,0,10);
    }

    rendererInit(){
        renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.localClippingEnabled = true;

        window.renderer = renderer;
        document.body.appendChild(renderer.domElement);
    }
}