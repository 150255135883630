import * as THREE from 'three';
import { ScaleManager } from './ScaleManager';

var texture_save;
var positionButton;

var controller;

export class PositionManager{

    constructor(controller){
        this.controller = controller;
    }

    getPositionButton(){
        return positionButton;
    }

    // Helper function to get intersected objects
    getIntersects(x, y) {
        var mouse = new THREE.Vector2();
        mouse.x = (x / window.innerWidth) * 2 - 1;
        mouse.y = - (y / window.innerHeight) * 2 + 1;

        var raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        return raycaster.intersectObjects(scene.children, true);
    }

    // Function to add the position button
    addPositionButton(textMesh, maxDistance) {
        var positionManager = this;

        var loader = new THREE.TextureLoader();
        loader.load('move.png', function(texture) {
            texture_save = texture;
            var buttonGeometry = new THREE.CircleGeometry(0.03, 9000);
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture, color: 0xffffff, transparent:false, opacity: 1 });
            positionButton = new THREE.Mesh(buttonGeometry, buttonMaterial);

            // Initial position of the position button (center of the text)
            positionButton.position.copy(textMesh.position);
            positionButton.position.z += 0.2;

            scene.add(positionButton);

            var isDraggingPosition = false;
            var dragStart = new THREE.Vector3();
            var dragOffset = new THREE.Vector3();

            function onPositionMouseDown(event) {
                var intersects = positionManager.getIntersects(event.clientX, event.clientY);
                if (intersects.length > 0 && intersects[0].object === positionButton) {
                    isDraggingPosition = true;
                    dragStart.set(event.clientX, event.clientY, 0);
                    dragOffset.copy(positionButton.position);
                }
            }

            function onPositionMouseMove(event) {
                if (!isDraggingPosition) return;

                positionManager.controller.changeOpacity(0.4);

                var dragCurrent = new THREE.Vector3(event.clientX, event.clientY, 0);
                var delta = dragCurrent.clone().sub(dragStart).multiplyScalar(0.001); // Adjust sensitivity

                var newPositionX = dragOffset.x + delta.x;
                var newPositionY = dragOffset.y - delta.y;

                // Restrict the movement to within the maxDistance
                var distance = Math.sqrt(newPositionX * newPositionX + newPositionY * newPositionY);
                if (distance > maxDistance) {
                    newPositionX = maxDistance * (newPositionX / distance);
                    newPositionY = maxDistance * (newPositionY / distance);
                }

                var circleMesh = positionManager.controller.getScaleManager().getCircleMesh();
                var rotationButton = positionManager.controller.getRotationManager().getRotationButton();
                var scaleButton = positionManager.controller.getScaleManager().getScaleButton();

                
                var prevPos = new THREE.Vector3(positionButton.position.x, positionButton.position.y, positionButton.position.z);
                circleMesh.position.set(newPositionX, newPositionY, circleMesh.position.z);
                positionButton.position.set(newPositionX, newPositionY, positionButton.position.z);

                var delta = prevPos.sub(positionButton.position);

                rotationButton.position.set(rotationButton.position.x - delta.x, rotationButton.position.y - delta.y, rotationButton.position.z + delta.z);
                scaleButton.position.set(scaleButton.position.x - delta.x, scaleButton.position.y - delta.y, scaleButton.position.z - delta.z);

                // Update the text position to follow the position button
                textMesh.position.x = newPositionX;
                textMesh.position.y = newPositionY;
            }

            function onPositionMouseUp() {
                if (isDraggingPosition){
                    positionManager.controller.changeOpacity(1);
                }
                isDraggingPosition = false;
            }

            function onPositionTouchStart(event) {
                if (event.touches.length === 1) {
                    var touch = event.touches[0];
                    onPositionMouseDown(touch);
                }
            }

            function onPositionTouchMove(event) {
                if (event.touches.length === 1) {
                    var touch = event.touches[0];
                    onPositionMouseMove(touch);
                }
            }

            function onPositionTouchEnd(event) {
                if (event.touches.length === 0) {
                    onPositionMouseUp();
                }
            }

            // Add event listeners for dragging
            document.addEventListener('mousedown', onPositionMouseDown);
            document.addEventListener('mousemove', onPositionMouseMove);
            document.addEventListener('mouseup', onPositionMouseUp);
            document.addEventListener('touchstart', onPositionTouchStart);
            document.addEventListener('touchmove', onPositionTouchMove);
            document.addEventListener('touchend', onPositionTouchEnd);

        });
        
    }

    changeOpacity(opacity){
        if (opacity == 1){
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture_save, color: 0xffffff, transparent: false, opacity: opacity});
            positionButton.material = buttonMaterial;
        }
        else {
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture_save, color: 0xffffff, transparent: true, opacity: opacity});
            positionButton.material = buttonMaterial;
        }
        
    }

    hideToggle(){
        if(positionButton.material.opacity > 0){
            this.changeOpacity(0);
        }
        else {
            this.changeOpacity(1);
        }
    }
}