import { ScaleManager } from './ScaleManager.js';
import { PositionManager } from './PositionManager.js';
import { RotationManager } from './RotationManager.js';

var scaleManager, positionManager, rotationManager;

export class Controller {

    constructor() {
        scaleManager = new ScaleManager(this);
        positionManager = new PositionManager(this);
        rotationManager = new RotationManager(this);
    }

    getScaleManager(){
        return scaleManager;
    }

    getRotationManager(){
        return rotationManager;
    }

    getPositionManager(){
        return positionManager;
    }

    changeOpacity(opacity){

        scaleManager.changeOpacity(opacity);
        rotationManager.changeOpacity(opacity);
        positionManager.changeOpacity(opacity);
    }

    hideToggle(){
        scaleManager.hideToggle();
        rotationManager.hideToggle();
        positionManager.hideToggle();
    }
}