import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Loader } from './loader.js';
import { Initializer } from './Initializer.js';
import { TextManager } from './TextManager.js';
import { Controller } from './Controller.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import { SVGRenderer } from 'three/examples/jsm/renderers/SVGRenderer.js';

var controls, loader, initializer;
var textManager;
var controller;



initializer = new Initializer();
loader = new Loader();
textManager = new TextManager();

controller = new Controller();



initializer.initialize();

// controls = new OrbitControls(camera, renderer.domElement);
// controls.update(); 

loader.loadTshirt(() => {
    textManager.addTextToTshirt(tshirtMesh, () => {
        window.textmesh = textManager.getTextMesh();
        controller.getScaleManager().addCircleAndButton(textManager.getTextMesh());
        controller.getPositionManager().addPositionButton(textManager.getTextMesh(), 2);
        controller.getRotationManager().addRotationButton(textManager.getTextMesh(), 0.12);
    });
    
});


// function saveCanvasAsImage() {
//   const rendererSVG = new SVGRenderer();
//   rendererSVG.setSize(window.innerWidth, window.innerHeight);
  
//   // Render the scene with SVGRenderer
//   rendererSVG.render(scene, camera);

//   // Serialize the SVG and save it
//   ExportToSVG(rendererSVG, "test.svg");
// }

// function ExportToSVG(rendererSVG, filename) {
//   const XMLS = new XMLSerializer();
//   const svgfile = XMLS.serializeToString(rendererSVG.domElement);
//   const svgData = svgfile;
//   const preface = '<?xml version="1.0" standalone="no"?>\r\n';
//   const svgBlob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
//   const svgUrl = URL.createObjectURL(svgBlob);
//   const downloadLink = document.createElement("a");

//   downloadLink.href = svgUrl;
//   downloadLink.download = filename;
//   document.body.appendChild(downloadLink);
//   downloadLink.click();
//   document.body.removeChild(downloadLink);
// }


function animate() {
    requestAnimationFrame(animate);
    // controls.update();
    renderer.render(scene, camera);
}


animate();

document.getElementById('button').addEventListener('click', function() {
    controller.hideToggle();
    // saveCanvasAsImage(window.textmesh.geometry);
});

document.getElementById('export-svg-button').addEventListener('click', function() {

    function measureTextWidth(text, fontFamily, fontSize) {
        const svgNamespace = "http://www.w3.org/2000/svg";
        const tempSvg = document.createElementNS(svgNamespace, "svg");
        const tempText = document.createElementNS(svgNamespace, "text");
        tempText.setAttribute("font-family", fontFamily);
        tempText.setAttribute("font-size", fontSize);
        tempText.textContent = text;
        tempSvg.appendChild(tempText);
        document.body.appendChild(tempSvg);
        const bbox = tempText.getBBox();
        document.body.removeChild(tempSvg);
        return bbox.width;
    }

    function getDPI() {
        const div = document.createElement("div");
        div.style.width = "1in";
        div.style.position = "absolute";
        div.style.top = "-100%";
        document.body.appendChild(div);
        const dpi = div.offsetWidth;
        document.body.removeChild(div);
        return dpi;
    }

    function createSVGText(text, fontSize, fontFamily, color, desiredWidthCm) {
        const svgNamespace = "http://www.w3.org/2000/svg";

        const dpi = getDPI();
        

        const initialWidthPx = measureTextWidth(text, fontFamily, fontSize);

            // Calculate the desired width in pixels (96 pixels per inch * 2.54 cm per inch)
            const desiredWidthPx = (desiredWidthCm / 2.54) * dpi;

            // Calculate the scaling factor
            const scale = desiredWidthPx / initialWidthPx;

            // Calculate the final SVG dimensions
            const svgWidthCm = desiredWidthCm;
            const svgHeightCm = (desiredWidthPx / initialWidthPx) * fontSize / 96 * 2.54;

        // const svgWidth = 300 * scale;
        // const svgHeight = 200 * scale;

        const svg = document.createElementNS(svgNamespace, "svg");
        svg.setAttribute("xmlns", svgNamespace);
        svg.setAttribute("width", 0);
        svg.setAttribute("height", 0);
        svg.setAttribute("viewBox", `0 0 ${0} ${0}`);


        // Define <defs> and <style> to embed custom font
        const defs = document.createElementNS(svgNamespace, "defs");
        const style = document.createElementNS(svgNamespace, "style");
        style.textContent = `
            @font-face {
                font-family: "${fontFamily}";
                src: url('https://fonts.gstatic.com/s/fredokaone/v14/k3kUo8kEI-tA1RRcTZGmTlHGCac.woff2');
            }
            text {
                font-family: "${fontFamily}";
                font-size: ${fontSize * scale}px;
                fill: ${color};
                dominant-baseline: middle;
                text-anchor: middle;
            }
        `;
        defs.appendChild(style);
        svg.appendChild(defs);

        const textElement = document.createElementNS(svgNamespace, "text");
        textElement.setAttribute("x", "50%");
        textElement.setAttribute("y", "50%");
        textElement.setAttribute("dominant-baseline", "middle");
        textElement.setAttribute("text-anchor", "middle");
        // textElement.setAttribute("font-family", fontFamily);
        // textElement.setAttribute("font-size", (fontSize * scale) );
        textElement.setAttribute("fill", color);
        textElement.textContent = text;

        svg.appendChild(textElement);

        const svgData = new XMLSerializer().serializeToString(svg);
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const svgUrl = URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "text.svg";
        downloadLink.click();
        URL.revokeObjectURL(svgUrl);
    }

    var textMaxX = textManager.getTextMesh().geometry.boundingBox.max.x;
    var textMinX = textManager.getTextMesh().geometry.boundingBox.min.x;
    var textDelta = textMaxX - textMinX;

    var tshirtMaxX = tshirtMesh.children[0].geometry.boundingBox.max.x;
    var tshirtMinX = tshirtMesh.children[0].geometry.boundingBox.min.x;
    var tshirtDelta = tshirtMaxX - tshirtMinX;

    var textLengthInCm = textDelta / tshirtDelta * 100;

    createSVGText("Test", 50, 'test', "black", textDelta * 100 * textManager.getTextMesh().scale.x);
});



