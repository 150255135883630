import * as THREE from 'three';

var texture_save;
var rotationButton;

var controller;

export class RotationManager {

    constructor(controller){
        this.controller = controller;
    }

    getRotationButton(){
        return rotationButton;
    }

    // Helper function to get intersected objects
    getIntersects(x, y) {
        var mouse = new THREE.Vector2();
        mouse.x = (x / window.innerWidth) * 2 - 1;
        mouse.y = - (y / window.innerHeight) * 2 + 1;

        var raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        return raycaster.intersectObjects(scene.children, true);
    }

    // Function to add the rotation button
    addRotationButton(textMesh, maxDistance) {
        var rotationManager = this;
        var loader = new THREE.TextureLoader();
        loader.load('rotate.png', function(texture) {
            texture_save = texture;
            var buttonGeometry = new THREE.CircleGeometry(0.03, 9000);
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture, color: 0xffffff, transparent:false, opacity: 1 });
            rotationButton = new THREE.Mesh(buttonGeometry, buttonMaterial);

            // Initial position of the rotation button (slightly above the text)
            rotationButton.position.set(0, maxDistance, 0.4);

            scene.add(rotationButton);

            var isDraggingRotation = false;
            var center = new THREE.Vector3();
            var dragStart = new THREE.Vector3();
            var initialAngle = 0;

            function onRotationMouseDown(event) {
                var intersects = rotationManager.getIntersects(event.clientX, event.clientY);
                if (intersects.length > 0 && intersects[0].object === rotationButton) {
                    isDraggingRotation = true;
                    center.copy(textMesh.position);
                    dragStart.set(event.clientX, event.clientY, 0);

                    // Calculate initial angle
                    var offset = new THREE.Vector3().subVectors(rotationButton.position, center);
                    initialAngle = Math.atan2(offset.y, offset.x);
                }
            }

            function onRotationMouseMove(event) {
                if (!isDraggingRotation) return;

                rotationManager.controller.changeOpacity(0.4);

                var mouse = new THREE.Vector3(event.clientX, event.clientY, 0);
                var vector = new THREE.Vector3();

                var center = rotationManager.controller.getPositionManager().getPositionButton().position;
                // Calculate the angle between the center and the current mouse position
                // Project the center point to screen space
                var centerScreenPosition = center.clone().project(camera);
                centerScreenPosition.x = (centerScreenPosition.x * 0.5 + 0.5) * window.innerWidth;
                centerScreenPosition.y = (centerScreenPosition.y * -0.5 + 0.5) * window.innerHeight;

                // Calculate the angle between the center and the current mouse position
                var angle = -Math.atan2(mouse.y - centerScreenPosition.y, mouse.x - centerScreenPosition.x);

                
                // Update the rotation button position along a circle
                rotationButton.position.x = center.x + maxDistance * Math.cos(angle);
                rotationButton.position.y = center.y + maxDistance * Math.sin(angle);

                // Rotate the text based on the direction
                textMesh.rotation.z = angle - (Math.PI / 2);
            }

            function onRotationMouseUp() {
                if (isDraggingRotation){
                    rotationManager.controller.changeOpacity(1);
                }
                isDraggingRotation = false;
                
            }

            function onRotationTouchStart(event) {
                if (event.touches.length === 1) {
                    var touch = event.touches[0];
                    onRotationMouseDown(touch);
                }
            }

            function onRotationTouchMove(event) {
                if (event.touches.length === 1) {
                    var touch = event.touches[0];
                    onRotationMouseMove(touch);
                }
            }

            function onRotationTouchEnd(event) {
                if (event.touches.length === 0) {
                    onRotationMouseUp();
                }
            }

            // Add event listeners for dragging
            document.addEventListener('mousedown', onRotationMouseDown);
            document.addEventListener('mousemove', onRotationMouseMove);
            document.addEventListener('mouseup', onRotationMouseUp);
            document.addEventListener('touchstart', onRotationTouchStart);
            document.addEventListener('touchmove', onRotationTouchMove);
            document.addEventListener('touchend', onRotationTouchEnd);
        });
        
    }

    changeOpacity(opacity){
        if (opacity == 1){
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture_save, color: 0xffffff, transparent: false, opacity: opacity});
            rotationButton.material = buttonMaterial;
        }
        else {
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture_save, color: 0xffffff, transparent: true, opacity: opacity});
            rotationButton.material = buttonMaterial;
        }
        
    }

    hideToggle(){
        if(rotationButton.material.opacity > 0){
            this.changeOpacity(0);
        }
        else {
            this.changeOpacity(1);
        }
    }
}