import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

var textMesh;

export class TextManager{

    addTextToTshirt(tshirt, onFinished = {}) {
        var textManager = this;
        
        var loader = new FontLoader();
        loader.load('Fredoka One_Regular.json', function(font) {
            var textGeometry = new TextGeometry('Test', {
                font: font,
                size: 0.07,
                depth: 0.01,
                height: 0.05,
                curveSegments: 44,
            });
    
            textGeometry.center();
            // Curve the text
            textManager.curveText(textGeometry, -0.38); // Adjust the radius for desired curvature
    
            var bbox = new THREE.Box3().setFromObject(tshirt);
    
            var clippingPlanes = [
                new THREE.Plane(new THREE.Vector3(0.35, 0, 0), bbox.max.x), // Right plane
                new THREE.Plane(new THREE.Vector3(-0.35, 0, 0), -bbox.min.x), // Left plane
                // new THREE.Plane(new THREE.Vector3(0, 0.001, 0), -bbox.max.y), // Top plane
                // new THREE.Plane(new THREE.Vector3(0, -0.001, 0), bbox.min.y) // Bottom plane
            ];
    
            var textMaterial = new THREE.MeshBasicMaterial({
                color: 0x000000,
                clippingPlanes: clippingPlanes,
                clipShadows: true
            });
            // var textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
            textMesh = new THREE.Mesh(textGeometry, textMaterial);

            // Position the text on the T-shirt
            textMesh.position.set(0, 0, 0.12); // Adjust these values based on your model's dimensions
            textMesh.rotation.set(0, 0, 0); // Rotate to align with the T-shirt surface
    
            tshirt.add(textMesh);
            onFinished();        
        });
    }
    
    curveText(textGeometry, radius) {
        var positionAttribute = textGeometry.attributes.position;
        var vector = new THREE.Vector3();
    
        for (var i = 0; i < positionAttribute.count; i++) {
            vector.fromBufferAttribute(positionAttribute, i);
    
            var angle = vector.x / radius; // Adjust for desired curvature
            var y = radius * Math.sin(angle);
            y = 0;
            var z = radius * (1 - Math.cos(angle));
    
            vector.y += y; // Adjust the y position to add the curve
            vector.z += z; // Adjust the z position to add the curve
    
            positionAttribute.setXYZ(i, vector.x, vector.y, vector.z);
        }
    
        positionAttribute.needsUpdate = true;
    }
    
    getTextMesh(){
        return textMesh;
    }
}