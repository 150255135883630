import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

var loader = new GLTFLoader();
const modelPath = 'tshirt_main.glb'; // Use a relative path


export class Loader {


    loadTshirt(onLoaded = {}) {

        loader = new GLTFLoader();
        loader.load(modelPath, function (gltf) {
            scene.add(gltf.scene);
            window.tshirtMesh = gltf.scene;
            onLoaded();
        }, undefined, function (error) {
            console.error('An error occurred while loading the model:', error);
        });
    }
}