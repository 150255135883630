import * as THREE from 'three';
import { PositionManager } from './PositionManager.js';
import { RotationManager } from './RotationManager.js';
import { DragControls } from 'three/examples/jsm/controls/DragControls.js';

var initialScaleButtonPos = new THREE.Vector3();
var maxDistance = 0.45;
var prevPos = new THREE.Vector3;

var scaleFactor = 1;
var textDistance = 0;
var savedDistance = 0;
var radius_value = 0.28;
var texture_save;
var scaleButton, circleMesh;

export class ScaleManager{

    constructor(control){
        this.controller = control;
    }

    addCircleAndButton(textMesh) {
        var scaleManager = this;
        var loader = new THREE.TextureLoader();
        loader.load('scale.png', function(texture) {
            texture_save = texture;
            var circleGeometry = new THREE.RingGeometry(0.12, 0.125, 9000);
            var circleMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
            circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);
            
            // Position the circle around the text
            circleMesh.position.copy(textMesh.position);
            circleMesh.position.z += 0.1;
            circleMesh.rotation.set(0, 0, 0);
        
            // Create the scale button
            var buttonGeometry = new THREE.CircleGeometry(0.04, 9000);
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture, color: 0xffffff, transparent:false, opacity: 1 });
            scaleButton = new THREE.Mesh(buttonGeometry, buttonMaterial);
        
            // Position the button on the circle's edge
            scaleButton.position.set(0.2, 0.2, 0.2);
            initialScaleButtonPos.copy(scaleButton.position); // Store initial position
            prevPos.copy(scaleButton.position);
        
            scene.add(circleMesh);
            scene.add(scaleButton);
        
            // Add dragging functionality
            scaleManager.addDragControls(scaleButton, textMesh, circleMesh);
        });
    }

    addDragControls(scaleButton, textMesh, circleMesh) {
        var scaleManager = this;
        var dragControls = new DragControls([scaleButton], camera, renderer.domElement);
    
        dragControls.addEventListener('drag', function(event) {
            scaleManager.controller.changeOpacity(0.4);
            
            var center = scaleManager.controller.getPositionManager().getPositionButton().position;
            var delta = center.clone().sub(new THREE.Vector3(0,0,0));

            var pos = scaleButton.position;
            var newPos = new THREE.Vector3((pos.x + pos.y) / 2 + delta.x, (pos.x + pos.y) / 2 + delta.y, pos.z);
    

            // Calculate the distance from the initial position
            var distance = newPos.distanceTo(initialScaleButtonPos);
            // Restrict movement if distance exceeds the maximum allowed distance
            if (distance > maxDistance) {
                newPos.set(prevPos.x, prevPos.y, prevPos.z);
            }
    
            scaleButton.position.copy(newPos);
            
            // Calculate the distance to the text mesh for scaling
            textDistance = Math.abs(savedDistance + scaleButton.position.distanceTo(center));

            
            // Calculate the scale factor based on the distance
            scaleFactor = textDistance / 0.28; // Adjust the divisor based on your initial circle radius
            // Apply the scale to the text
            textMesh.scale.set(scaleFactor, scaleFactor, scaleFactor);
    
            prevPos.set(scaleButton.position.x, scaleButton.position.y, scaleButton.position.z);
            
        });
    
        dragControls.addEventListener('dragend', function(event) {

            scaleManager.controller.changeOpacity(1);
            var center = scaleManager.controller.getPositionManager().getPositionButton().position;
            var delta = center.clone().sub(new THREE.Vector3(0,0,0));

            savedDistance += scaleButton.position.distanceTo(center) - radius_value;
            // Ensure the button stays on the circle's edge along the line y = x
            var pos = scaleButton.position;
            var newPos = new THREE.Vector3((pos.x + pos.y) / 2 + delta.x, (pos.x + pos.y) / 2 + delta.y, pos.z);
            
            // initialScaleButtonPos.set(center.x, center.y, center.z);
            // Calculate the distance from the initial position
            var distance = newPos.distanceTo(initialScaleButtonPos);
    
            // Restrict movement if distance exceeds the maximum allowed distance
            if (distance > maxDistance) {
                var direction = newPos.clone().sub(initialScaleButtonPos).normalize();
                newPos = initialScaleButtonPos.clone().add(direction.multiplyScalar(maxDistance));
                newPos.set(newPos.x + delta.x, newPos.y + delta.y, newPos.z);
            }
            // else if (distance <= 0.23){
            //     var direction = newPos.clone().sub(center).normalize();
            //     newPos = center.clone().add(direction.multiplyScalar(maxDistance));
            // }
            // if (newPos.x < center.x){
            //     var direction = newPos.clone().sub(center).normalize();
            //     newPos = center.clone().add(direction.multiplyScalar(maxDistance));

            //     if (newPos.x < center.x){
            //         newPos.x = -newPos.x;
            //     }
            //     newPos.z = 0.2;
            // }
    
            // Ensure the position is along the line y = x and on the circle's edge
            var radius = radius_value; // Circle radius
            var angle = Math.atan2(newPos.y, newPos.x);
            var restrictedX =  radius * Math.cos(angle);
            var restrictedY =  radius * Math.sin(angle);
            var adjustedPos = new THREE.Vector3(Math.abs(restrictedX + restrictedY) / 2 , Math.abs(restrictedX + restrictedY) / 2, newPos.z);
            // Restrict movement if distance exceeds the maximum allowed distance
            var finalDistance = adjustedPos.distanceTo(initialScaleButtonPos);
            // if (finalDistance > maxDistance) {
            //     var finalDirection = adjustedPos.clone().sub(initialScaleButtonPos).normalize();
            //     adjustedPos = initialScaleButtonPos.clone().add(finalDirection.multiplyScalar(maxDistance));
            //     adjustedPos.z = 0.2;
            // }

            adjustedPos.set(adjustedPos.x + delta.x, adjustedPos.y + delta.y, adjustedPos.z);
    
            scaleButton.position.copy(adjustedPos);
        });
    }

    changeOpacity(opacity){
        if (opacity == 1){
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture_save, color: 0xffffff, transparent: false, opacity: opacity});
            scaleButton.material = buttonMaterial;

            var circleMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide, transparent: false, opacity: opacity });
            circleMesh.material = circleMaterial;
        }
        else {
            var buttonMaterial = new THREE.MeshBasicMaterial({ map: texture_save, color: 0xffffff, transparent: true, opacity: opacity});
            scaleButton.material = buttonMaterial;

            var circleMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide, transparent: true, opacity: opacity });
            circleMesh.material = circleMaterial;
        }
        
    }

    hideToggle(){
        if(scaleButton.material.opacity > 0){
            this.changeOpacity(0);
        }
        else {
            this.changeOpacity(1);
        }
    }

    getCircleMesh(){
        return circleMesh;
    }

    getScaleButton(){
        return scaleButton;
    }
}